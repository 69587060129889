<template>
  <div>
    <div class="text-right">
      <b-button
        variant="primary"
        class="btn-tour-skip mb-1"
        @click="goTo('OrderCreate', '', { pin: client.pin })"
      >
        <feather-icon
          class="float-end"
          size="18"
          icon="PlusSquareIcon"
        />
        {{ $t("Создать договор") }}
      </b-button>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card
          v-if="client"
          class="earnings-card"
        >
          <h3 class="text-primary text-center">
            {{
              [client.lastname, client.firstname, client.middlename].join(" ")
            }}
          </h3>
          <b-row class="mb-1">
            <b-col cols="2">
              <b>Telefoni</b>:
            </b-col>
            <b-col cols="6">
              <span>+998{{ client.phone }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="2">
              <b>Manzili</b>:
            </b-col>
            <b-col cols="6">
              <span v-if="client.region">
                {{
                  client.region.name_uz +
                    ", " +
                    client.city.name_uz +
                    ", " +
                    client.address
                }}
              </span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="2">
              <b>Tug'ilgan sana</b>:
            </b-col>
            <b-col cols="6">
              {{ formatDateYmd(client.date_birth, "DD.MM.YYYY") }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="2">
              <b>PINFL</b>:
            </b-col>
            <b-col cols="6">
              <span>{{ client.pin }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="2">
              <b>Pasport</b>:
            </b-col>
            <b-col cols="6">
              {{ client.doc_series + "" + client.doc_number }} ({{
                client.issue_by
              }})
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Contracts -->
    <b-row v-if="contracts">
      <b-col cols="12">
        <b-card class="earnings-card">
          <h3 class="text-primary text-center">
            Mijoz bilan tuzilgan shartnomalar
          </h3>
          <div
            v-for="(item, index) in contracts.data"
            :key="index"
          >
            <h3 class="text-center">
              {{ index + 1 }}
            </h3>
            <b-row class="mb-1">
              <b-col cols="3">
                <b>Shartnoma raqami </b>:
              </b-col>
              <b-col cols="6">
                <span
                  class="text-primary cursor-pointer"
                  @click="goTo('payment-show', { id: item.id })"
                >{{ item.id }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <b>Shartnoma tuzilgan sanasi </b>:
              </b-col>
              <b-col cols="6">
                <span>{{
                  item.start_date ? dateDotFormat(item.start_date) : ""
                }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <b>Shartnoma tugash sanasi </b>:
              </b-col>
              <b-col cols="6">
                <span>{{
                  item.start_date ? dateDotFormat(item.end_date) : ""
                }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <b>Shartnoma muddati </b>:
              </b-col>
              <b-col cols="6">
                <span>{{ item.credit_months }} oy</span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <b>Shartnoma summasi </b>:
              </b-col>
              <b-col cols="6">
                <span>{{ item.credit_amount | formatPrice }} so'm</span>
              </b-col>
            </b-row>
            <hr>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- Bonus -->
    <b-row>
      <b-col cols="12">
        <b-card class="earnings-card">
          <h3 class="text-primary text-center">
            Mijoz cashback summasi
          </h3>
          <h4 class="text-center">
            <span class="display-4">
              {{ bonus | formatPrice }}
            </span>
            so'm
          </h4>
          <hr>
          <b-button
            v-b-toggle.collapse-1
            variant="primary"
            @click="getCashbacks"
          >
            Ro'yxatini ko'rish
          </b-button>
          <b-collapse
            id="collapse-1"
            class="mt-2"
          >
            <b-overlay :show="loading">
              <GoodTableSelectable
                v-if="cashbacks && cashbacks.data && cashbacks.data.length"
                :items="cashbacks.data"
                :columns="columns"
                :total="cashbacks.total"
                :page="cashbackFilter.page"
                :filter="cashbackFilter"
                model="payment"
                :has-contract-show="true"
                @getItems="getCashbacks"
                @onPageChange="(p) => (cashbackFilter.page = p)"
              />
              <div v-else>
                Нет оплаты
              </div>
            </b-overlay>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoodTableSelectable from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'

export default {
  components: { GoodTableSelectable },
  data() {
    return {
      contracts: null,
      bonus: null,
      cashbacks: null,
      loading: false,
      cashbackFilter: {
        page: 1,
        per_page: 50,
        pin: null,
        relations: 'payment',
      },
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'Shartnoma',
          field: 'contract_id',
          showField: true,
        },
        {
          label: 'Keshbak summasi',
          field: 'amount',
          isAmount: true,
        },
        {
          label: 'Izoh',
          field: 'payment.comment',
        },
        {
          label: this.$t('Дата оплаты'),
          isDateTime: true,
          field: 'created_at',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ client: 'client/GET_ITEM' }),
  },
  watch: {
    'cashbackFilter.page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getCashbacks()
    },
    'cashbackFilter.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getCashbacks()
    },
  },
  mounted() {
    this.getClient({
      id: this.$route.params.id,
      relations: 'region|city',
    }).then(() => {
      this.getContracts()
      this.getCashback()
    })
  },
  methods: {
    ...mapActions({
      cashbacksList: 'resource/cashbacksList',
      getContractsAction: 'contract/index',
      getClient: 'client/show',
      getClientCashback: 'client/clientCashback',
    }),
    getContracts() {
      this.getContractsAction({
        tab: 'active',
        'client.pin': this.client.pin,
        relations: 'client|createdUser',
      }).then(res => {
        if (res.data && res.data.data.length) {
          this.contracts = res.data
        }
      })
    },
    getCashbacks() {
      console.log(111)
      this.cashbackFilter.pin = this.client.pin
      this.loading = true
      this.cashbacksList(this.cashbackFilter).then(res => {
        this.cashbacks = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    async getCashback() {
      await this.getClientCashback({ pin: this.client.pin }).then(res => {
        if (res.data) {
          this.bonus = res.data
        }
      })
    },
  },
}
</script>

<style></style>
